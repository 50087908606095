<template>
  <div class="h-100 w-100" :class="{ ['d-flex justify-content-center align-items-center']: loading }">
    <div v-if="loading" v-loading="loading" />
    <div v-else class="h-100 w-100 p-5">
      <div class="event-header-container mb-6" :class="{ ['notification']: $slots.notification }">
        <div class="event-header-wrapper">
          <slot name="header" />
        </div>
        <div v-if="$slots.notification" style="max-width: 600px">
          <slot name="notification" />
        </div>
        <div class="d-flex flex-row-reverse justify-content-start">
          <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
            <CloseIcon />
          </Button>
          <el-dropdown
            v-if="actions.length && showActions"
            trigger="click"
            style="height: fit-content"
            :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
            @command="(command) => $emit('action', command)"
          >
            <Button type="text" class="p-0 text-typography-primary d-flex action-btn">
              <KebabIcon />
            </Button>
            <el-dropdown-menu slot="dropdown">
              <div v-for="action in actions" :key="action.label" class="d-flex align-items-center">
                <el-dropdown-item
                  :key="action.label"
                  :command="action.event"
                  :disabled="action.disabled"
                  :class="{
                    'text-disabled': action.disabled,
                    'disable-dropdown-padding': action.tooltipText,
                    'text-typography-primary w-100': !action.disabled,
                  }"
                >
                  <div class="d-flex justify-content-between">
                    {{ action.label }}
                    <div class="lock-icon" v-if="action.noPermission">
                      <el-tooltip
                        :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
                        placement="top"
                      >
                        <LockInvertedIcon />
                      </el-tooltip>
                    </div>
                  </div>
                </el-dropdown-item>
                <el-tooltip
                  v-if="action.tooltipText"
                  class="item"
                  effect="dark"
                  :content="action.tooltipText"
                  placement="top"
                >
                  <QuestionMarkIcon :size="16" class="text-disabled" style="margin-inline-end: 20px" />
                </el-tooltip>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="event-content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
import { KebabIcon, CloseIcon, LockInvertedIcon, QuestionMarkIcon } from '@/assets/icons';
import { Button } from '@/modules/core/components';

export default {
  components: { KebabIcon, CloseIcon, Button, LockInvertedIcon, QuestionMarkIcon },
  props: {
    loading: {
      type: Boolean,
    },
    eventId: { type: String, required: true },
    eventType: { type: String, required: true },
    actions: { type: Array, default: () => [] },
    showActions: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

$header-hight: 80px;

.event-header-container {
  display: grid;
  height: $header-hight;
  grid-template-columns: 1fr 1fr;
  &.notification {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.event-content {
  height: calc(100% - ($header-hight + 3rem));
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

.lock-icon {
  margin-inline-start: 1rem;
}

.disable-dropdown-padding {
  [dir='rtl'] & {
    padding-left: 0.5rem;
  }

  [dir='ltr'] & {
    padding-right: 0.5rem;
  }
}
</style>
