<template>
  <MainLayout :loading="loading" :actions="actions" v-bind="$props" v-on="$listeners" @action="actionsHandler">
    <template #header>
      <h1>
        {{
          billing.type === 'balance'
            ? $t('eventMapModal.billingCard.balanceHeader', {
                variant: $tc('eventMapModal.billingCard.variant', billing.netAmount >= 0),
              })
            : $t('eventMapModal.billingCard.header', {
                variant: $tc('eventMapModal.billingCard.variant', billing.netAmount >= 0),
                documentNumber: document?.documentNumber,
                documentType: $t(`document.exports.schema.type.shortName.${document?.type}`),
              })
        }}
      </h1>
      <div>{{ billing.supplier.name || $t('commons.unknown') }}</div>
      <div>{{ formatDate(billing.date) || $t('commons.unknownDate') }}</div>
      <div v-if="billing.allocationRequired">
        {{ $t('eventMapModal.billingCard.allocationNumber', { number: allocationNumber ?? $t('commons.missing') }) }}
      </div>
    </template>
    <template #content>
      <div class="h-100 overflow-scroll">
        <Boxes :billing="billing" :related-billings="relatedBillings" />
        <div v-if="hasBillingManage" class="d-flex justify-content-end pb-4">
          <Button v-if="isAdmin" type="secondary" @click="matchInvoiceModalOpen = true">
            {{ $t('eventMapModal.billingCard.matchInvoiceToOrders') }}
          </Button>
        </div>
        <BillingTable :events="billingTableData" :imbalances="billing.netAmount >= 0 ? billing.imbalances : []" />
      </div>
      <MatchInvoiceModal
        v-if="matchInvoiceModalOpen"
        :billing="billing"
        @close="matchInvoiceModalOpen = false"
        @update="linkInvoiceToOrders"
      />
    </template>
  </MainLayout>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { uniq, flatten } from 'ramda';

import { Button } from '@/modules/core';
import { options } from '@/locale/dateConfig';
import { useUser } from '@/modules/auth';
import { useOrderCreate } from '@/modules/order';
import { useBillingPatch } from '@/modules/billing';
import { useGlobalPermissions } from '@/modules/permissions';

import { useBillingData, useBillingsByIds, useOrderDifferences } from '../../compositions/billing';
import { Boxes, BillingTable, MatchInvoiceModal } from './components';
import MainLayout from '../MainLayout';

const ACTION_EVENTS = {
  CREATE_BALANCE_ORDER: 'createBalanceOrder',
  CREATE_EVENTS_FROM_DOCUMENT: 'createEventsFromDocument',
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getRelatedBillingIds = (billing) => {
  return billing
    ? uniq(flatten(billing.imbalances.map(({ billedAmounts }) => billedAmounts.map(({ id }) => id)))).filter(
        (id) => id !== billing.id
      )
    : [];
};

export default {
  components: { MainLayout, Boxes, BillingTable, Button, MatchInvoiceModal },
  props: {
    eventId: { type: String, required: true },
    eventType: { type: String, required: true },
  },

  setup(props) {
    const root = getCurrentInstance().proxy;
    const { isAdmin } = useUser();
    const currentId = computed(() => props.eventId);
    const { hasPurchaseDeliveryManager, hasPurchaseOrderEdit, hasBillingManage } = useGlobalPermissions();
    const {
      billing,
      loading: billingLoading,
      refetch: billingRefetch,
      createOrderFromDocument,
      createDeliveryFromDocument,
    } = useBillingData(currentId);

    const {
      orderDifferences,
      loading: orderDifferencesLoading,
      refetch: orderDifferencesRefetch,
    } = useOrderDifferences(computed(() => ({ customerId: billing.value?.businessId })));

    const relevantOrderDifferences = computed(() =>
      orderDifferences.value.filter(({ eventReferences }) =>
        eventReferences.some(({ documentId }) => documentId === billing.value.eventReferences[0].document.id)
      )
    );

    const relatedBillingIds = computed(() => getRelatedBillingIds(billing.value));
    const { billings: relatedBillings } = useBillingsByIds(
      computed(() => ({
        ids: relatedBillingIds.value,
        businessId: billing.value?.businessId,
      }))
    );

    const { createOrder } = useOrderCreate();

    const {
      patchBilling,
      loading: patchBillingLoading,
      onDone: patchBillingOnDone,
      onError: patchBillingOnError,
    } = useBillingPatch();

    patchBillingOnDone(() => {
      root.$message.success(root.$t('commons.messages.action.success'));
      billingRefetch();
    });

    patchBillingOnError(() => root.$message.error(root.$t('commons.messages.action.error')));

    const isBillingWithoutOrders = computed(() => billing.value?.orderLinks.every((orderLink) => !orderLink.order));

    return {
      hasPurchaseDeliveryManager,
      hasPurchaseOrderEdit,
      hasBillingManage,
      isAdmin,
      currentId,
      billing,
      relatedBillings,
      document: computed(() => billing.value?.eventReferences?.[0]?.document),
      formatDate: (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, { ...options.long, timeZone: 'UTC' }),
      loading: computed(
        () => (billingLoading.value && !billing.value) || patchBillingLoading.value || orderDifferencesLoading.value
      ),
      billingTableData: computed(() => {
        return billing.value?.orderLinks
          .filter((orderLink) => !!orderLink.order)
          .reduce((data, { order, amount }) => {
            const existingOrderIndex = data.findIndex(({ id }) => id === order.id);
            if (existingOrderIndex >= 0) {
              data[existingOrderIndex] = {
                ...data[existingOrderIndex],
                totalBill: data[existingOrderIndex].totalBill + Number(amount / 100),
              };
            } else
              data.push({
                ...order,
                totalBill: Number(amount / 100),
                hasReceivedGoods: order.products.some((product) => product.quantity > 0),
                hasReturnRequests: order.products.some((product) => product.quantity < 0),
                deliveries: uniq(
                  billing.value.deliveryRefs
                    .filter(({ delivery }) => delivery && delivery.orderIds.includes(order.id))
                    .map(({ delivery }) => delivery)
                ),
                relatedBillings: relatedBillings.value.filter(({ orderLinks }) =>
                  orderLinks.some((link) => link.order && link.order.id === order.id)
                ),
              });
            return data;
          }, []);
      }),
      isBillingWithoutOrders,
      createOrderFromDocument,
      createDeliveryFromDocument,
      orderDifferencesRefetch,
      createOrder,
      patchBilling,
      billingRefetch,
      actions: computed(() => {
        return isAdmin.value &&
          isBillingWithoutOrders.value &&
          !relevantOrderDifferences.value.length &&
          hasPurchaseDeliveryManager.value &&
          hasPurchaseOrderEdit.value
          ? [
              {
                event: ACTION_EVENTS.CREATE_EVENTS_FROM_DOCUMENT,
                label: root.$t('eventMapModal.billingCard.createOrderDeliveryFromDocument'),
              },
              {
                event: ACTION_EVENTS.CREATE_BALANCE_ORDER,
                label: root.$t('eventMapModal.billingCard.orderAndDeliveryConfirmation'),
              },
            ]
          : [];
      }),
      matchInvoiceModalOpen: ref(false),
      allocationNumber: computed(
        () => billing.value?.eventReferences?.find(({ allocationNumber }) => allocationNumber)?.allocationNumber
      ),
    };
  },
  methods: {
    actionsHandler(event) {
      switch (event) {
        case ACTION_EVENTS.CREATE_BALANCE_ORDER:
          this.createBalanceOrder();
          break;
        case ACTION_EVENTS.CREATE_EVENTS_FROM_DOCUMENT:
          this.createEventsFromDocument();
          break;
        default:
          break;
      }
    },
    async createBalanceOrder() {
      const { businessId, supplier, date, netAmount } = this.billing;
      const {
        data: { orderCreate: orderCreate },
      } = await this.createOrder({
        createParams: {
          businessId,
          customerId: businessId,
          supplierId: supplier.id,
          date,
          type: 'balance',
          netAmount: netAmount / 100,
        },
      });

      await this.patchBilling({
        id: this.currentId,
        data: {
          orderLinks: [
            {
              orderId: orderCreate.id,
              amount: Math.round(orderCreate.netAmount * 100),
            },
          ],
        },
      });
    },
    async createEventsFromDocument() {
      this.$confirm(
        this.$t('eventMapModal.billingCard.confirmBox.description'),
        this.$t('eventMapModal.billingCard.confirmBox.createEvents'),
        {
          confirmButtonText: this.$t('eventMapModal.billingCard.confirmBox.createEvents'),
          cancelButtonText: this.$t('cancel'),
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(async () => {
          const loading = this.$loading();
          const {
            eventReferences: [
              {
                document: { id: documentId },
              },
            ],
          } = this.billing;
          try {
            await Promise.all([
              this.createOrderFromDocument({ documentId }),
              this.createDeliveryFromDocument({ documentId }),
            ]);
            await sleep(3000);
            await Promise.all([this.billingRefetch(), this.orderDifferencesRefetch()]);
            this.$message.success(this.$t('commons.messages.action.success'));
          } catch (err) {
            if (err.graphQLErrors?.[0].extensions.response?.status === 409)
              this.$message.error(this.$t('eventMapModal.billingCard.documentNotSupported'));
            else this.$message.error(this.$t('commons.messages.action.error'));
          } finally {
            loading.close();
          }
        })
        .catch(() => null);
    },
    async linkInvoiceToOrders({ orderLinks, deliveryRefs }) {
      await this.patchBilling({
        id: this.currentId,
        data: {
          orderLinks,
          deliveryRefs,
        },
      });
      this.matchInvoiceModalOpen = false;
    },
  },
};
</script>

<style scoped lang="scss" src="../../commons/style.scss" />
