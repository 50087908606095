<template>
  <div class="event-modal-card-boxes">
    <Box
      :title="
        $t('eventMapModal.billingCard.costBox.title', {
          variant: $tc('eventMapModal.billingCard.variant', billing.netAmount >= 0),
        })
      "
    >
      <div class="cost-row">
        <span>
          {{ $t('commons.vat-excluded') }}
        </span>
        <span>
          {{ formatMoney(billing.netAmount) }}
        </span>
      </div>
      <div class="cost-row">
        <span>
          {{ $t('commons.vat') }}
        </span>
        <span>
          {{ formatMoney(billing.taxAmount) }}
        </span>
      </div>
      <hr class="event-modal-card-boxes-hr--doted" />
      <div class="cost-row fw-bold">
        <span>
          {{ $t('commons.total') }}
        </span>
        <span>
          {{ formatMoney(billing.totalAmount) }}
        </span>
      </div>
    </Box>
    <Box
      :title="
        $t('eventMapModal.billingCard.detailsBox.title', {
          variant: $tc('eventMapModal.billingCard.variant', billing.netAmount >= 0),
        })
      "
    >
      <template v-if="billing.netAmount >= 0">
        <div v-for="(row, rowIndex) in detailsBoxRows" :key="`row-${rowIndex}`" class="details-row">
          <div v-for="(cell, cellIndex) in row" :key="`cell-${cellIndex}`" class="details-row__cell">
            <component :is="cell.icon" width="16px" height="16px" />
            <div>
              <div>
                {{ cell.text }}
              </div>
              <div class="event-modal-card-date">
                {{ cell.date }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <!-- this is work around until we will have credit matching TODO - remove it when we will have -->
      </template>
    </Box>
    <SummaryBox
      :documents="documents"
      :supplier-name="billing.supplier.name"
      :customer-name="business?.name || ''"
      :type="summaryBoxType"
    >
      <!-- this is work around until we will have credit matching TODO - remove it when we will have -->
      <template v-if="billing.netAmount >= 0">
        <div
          v-if="billing.imbalances.length"
          :class="{ 'text-decoration-line-through': relatedBillings.length && !imbalanceAmount }"
        >
          <div>
            {{
              $t('eventMapModal.billingCard.alertBox.error.orderImbalance', {
                billedAmount: formatMoney(billing.netAmount),
                orderedAmount: formatMoneyShekels(orderedAmount),
                singularPlural: summaryBoxSingularPluralText,
              })
            }}
          </div>
          <div>
            {{
              $t('eventMapModal.billingCard.alertBox.error.totalImbalance', {
                diffAmount: formatMoney(initialImbalanceAmount),
              })
            }}
          </div>
        </div>
        <div v-else>
          {{
            $t('eventMapModal.billingCard.alertBox.success.matchingStatus', {
              billedAmount: formatMoney(billing.netAmount),
              orderedAmount: formatMoneyShekels(orderedAmount),
              singularPlural: summaryBoxSingularPluralText,
            })
          }}
        </div>
        <div v-if="relatedBillings.length" class="d-flex gap-5">
          <div>
            <p class="text-typography-secondary">
              {{ $t('eventMapModal.billingCard.alertBox.matchedAmount') }}
            </p>
            <p v-if="imbalanceAmount" class="text-typography-secondary">
              {{ $t('eventMapModal.billingCard.alertBox.diffToMatch') }}
            </p>
          </div>
          <div>
            <div class="d-flex gap-1">
              {{ formatMoneyAbs(relatedBillingAmount) }}
              <el-tooltip placement="top" effect="dark">
                <div slot="content">
                  <div v-for="(line, index) in getMatchedCreditsTooltip(relatedBillings)" :key="index">
                    {{ line }}
                  </div>
                </div>
                <span class="d-flex rounded-circle bg-primary align-self-center icon-padding">
                  <LinkIconNew width="12" height="12" style="color: white" />
                </span>
              </el-tooltip>
            </div>
            <p v-if="imbalanceAmount" class="fw-bold">{{ formatMoney(imbalanceAmount) }}</p>
          </div>
        </div>
      </template>
    </SummaryBox>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import { defaultTo, subtract, sum, uniqBy, path } from 'ramda';

import { ApprovedIcon, ReturnedItemIcon, ChatIcon, DeliveryIcon, LinkIconNew } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { SummaryBox } from '../../../commons/components';
import Box, { BOX_TYPE } from '../../../commons/components/Box.vue';
import { useBusinessById } from '../../../compositions';

export default {
  name: 'Boxes',
  components: { Box, SummaryBox, LinkIconNew },
  props: {
    billing: { type: Object, required: true },
    relatedBillings: { type: Array, default: () => [] },
  },

  setup(props) {
    const root = getCurrentInstance().proxy;
    const businessId = computed(() => props.billing.businessId);
    const { business } = useBusinessById(businessId);
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    const counts = computed(() => ({
      ordered: props.billing.orderLinks.filter(({ order }) => order && order.products.some((p) => p.quantity > 0))
        .length,
      returnNotifications: props.billing.orderLinks.filter(
        ({ order }) => order && order.products.some((p) => p.quantity < 0)
      ).length,
      delivered: props.billing.deliveryRefs.filter(
        ({ delivery }) => delivery && delivery.products.some((p) => p.quantity > 0)
      ).length,
      returned: props.billing.deliveryRefs.filter(
        ({ delivery }) => delivery && delivery.products.some((p) => p.quantity < 0)
      ).length,
    }));

    const formatYearMonth = (ms) =>
      new Date(ms).toLocaleDateString(root.$i18n.locale, { year: 'numeric', month: 'long', timeZone: 'UTC' });

    const orderedAmount = computed(() => {
      const uniqueOrderLinksByBilling = uniqBy(path(['order', 'id']))(props.billing.orderLinks);
      return uniqueOrderLinksByBilling.reduce((acc, orderLink) => acc + defaultTo(0, orderLink.order?.amount), 0);
    });

    const initialImbalanceAmount = computed(() =>
      (props.billing.imbalances ?? []).reduce(
        (amount, { billedAmounts, orderedAmounts }) =>
          amount +
          subtract(
            sum(billedAmounts.map(({ id, amount }) => (id !== props.billing.id ? 0 : amount))),
            sum(orderedAmounts.map(({ amount }) => amount))
          ),
        0
      )
    );

    const imbalanceAmount = computed(() =>
      (props.billing.imbalances ?? []).reduce(
        (amount, { billedAmounts, orderedAmounts }) =>
          amount +
          subtract(sum(billedAmounts.map(({ amount }) => amount)), sum(orderedAmounts.map(({ amount }) => amount))),
        0
      )
    );

    const formatMoneyAbs = (value) => formatCentsToCurrency(Math.abs(Number(value))) ?? '-';

    return {
      business,
      documents: props.billing.eventReferences.map(({ document }) => document),
      formatMoney: (value) => formatCentsToCurrency(value) ?? '-',
      formatMoneyShekels: (value) => formatToCurrency(value) ?? '-',
      formatMoneyAbs,
      supplierName: computed(() => props.billing.supplierName),
      summaryBoxType: computed(() => {
        // this is work around until we will have credit matching TODO - remove it when we will have
        return props.billing.netAmount < 0
          ? BOX_TYPE.NEUTRAL
          : props.billing.imbalances.filter(({ resolved }) => !resolved).length
          ? BOX_TYPE.ERROR
          : BOX_TYPE.SUCCESS;
      }),
      summaryBoxSingularPluralText: computed(() =>
        root.$i18n.tc('eventMapModal.billingCard.alertBox.order', props.billing.orderLinks.length)
      ),
      detailsBoxRows: [
        [
          {
            text: root.$i18n.t('eventMapModal.billingCard.detailsBox.orders', { count: counts.value.ordered }),
            icon: ApprovedIcon,
            date: root.$i18n.t('eventMapModal.billingCard.detailsBox.duringDate', {
              date: formatYearMonth(props.billing.date),
            }),
          },
          {
            text: root.$i18n.t('eventMapModal.billingCard.detailsBox.orderReturnRequests', {
              count: counts.value.returnNotifications,
            }),
            icon: ChatIcon,
            date: root.$i18n.t('eventMapModal.billingCard.detailsBox.duringDate', {
              date: formatYearMonth(props.billing.date),
            }),
          },
        ],
        [
          {
            text: root.$i18n.t('eventMapModal.billingCard.detailsBox.deliveries', { count: counts.value.delivered }),
            icon: DeliveryIcon,
            date: root.$i18n.t('eventMapModal.billingCard.detailsBox.duringDate', {
              date: formatYearMonth(props.billing.date),
            }),
          },
          {
            text: root.$i18n.t('eventMapModal.billingCard.detailsBox.deliveryReturnedGoods', {
              count: counts.value.returned,
            }),
            icon: ReturnedItemIcon,
            date: root.$i18n.t('eventMapModal.billingCard.detailsBox.duringDate', {
              date: formatYearMonth(props.billing.date),
            }),
          },
        ],
      ],
      orderedAmount,
      initialImbalanceAmount,
      imbalanceAmount,
      BOX_TYPE,
      relatedBillingAmount: computed(() =>
        props.relatedBillings.reduce((total, { orderLinks }) => {
          const relevantOrderLink = orderLinks.find((link) =>
            props.billing.orderLinks.some(({ order }) => order && order.id === link.order?.id)
          );
          return total + relevantOrderLink.amount;
        }, 0)
      ),
      getMatchedCreditsTooltip: (relatedBillings) =>
        relatedBillings.map(({ eventReferences, orderLinks }) => {
          const relevantOrderLink = orderLinks.find((link) =>
            props.billing.orderLinks.some(({ order }) => order && order.id === link.order?.id)
          );
          return root.$i18n.t('eventMapModal.billingCard.alertBox.matchedCredits', {
            amount: formatMoneyAbs(relevantOrderLink.amount),
            documentNumber: eventReferences[0].document.documentNumber,
          });
        }),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '../../../commons/style.scss';

.cost-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.details-row {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 20px;

  .details-row__cell {
    display: grid;
    grid-template-columns: 1fr 20fr;
  }

  svg {
    color: $svg-gray-color;
    margin-top: 2px;

    [dir='rtl'] & {
      margin-left: 10px;
    }
    [dir='ltr'] & {
      margin-right: 10px;
    }
  }
}
.align-text-to-end {
  text-align: end;
}
</style>
