import { render, staticRenderFns } from "./Billing.vue?vue&type=template&id=8ddb600a&scoped=true"
import script from "./Billing.vue?vue&type=script&lang=js"
export * from "./Billing.vue?vue&type=script&lang=js"
import style0 from "../../commons/style.scss?vue&type=style&index=0&id=8ddb600a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ddb600a",
  null
  
)

export default component.exports