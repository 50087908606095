<template>
  <div>
    <Table :data="transformedEvents" :columns="columns" class="order-items-table" show-index border rounded>
      <template #cell-date="{ rowData: { date } }">
        {{ formatDate(date) }}
      </template>
      <template #cell-orderType="{ rowData: { hasReceivedGoods, hasReturnRequests } }">
        <div class="table-goods-cells">
          <div v-if="hasReceivedGoods" class="table-goods-cell">
            <div class="table-goods-cell__dots__dot table-goods-cell__dots__dot--received" />
            <div>
              {{ $t('eventMapModal.billingCard.billingTable.order') }}
            </div>
          </div>

          <div v-if="hasReturnRequests" class="table-goods-cell">
            <div
              :class="
                !hasReceivedGoods
                  ? 'table-goods-cell__dots__dot table-goods-cell__dots__dot--returned--only'
                  : 'table-goods-cell__dots__dot table-goods-cell__dots__dot--returned'
              "
            />
            <div>
              {{ $t('eventMapModal.billingCard.billingTable.orderReturnRequest') }}
            </div>
          </div>
        </div>
      </template>

      <template #cell-reference="{ rowData: { deliveries, refDocumentIds } }">
        <span v-for="delivery in deliveries" :key="delivery.id">
          <template v-for="ref in delivery.eventReferences">
            <Button
              :key="ref.document.id"
              type="link"
              class="p-0 m-0"
              @click.stop="handleOpenDocument(ref.document.id, refDocumentIds)"
            >
              {{ ref.document.type ? $t(`document.exports.schema.type.shortName.${ref.document.type}`) : '' }}
              {{ ref.document.documentNumber }}
            </Button>
            <span
              v-if="refDocumentIds.indexOf(ref.document.id) !== refDocumentIds.length - 1"
              :key="ref.document.documentNumber"
              class="px-1"
              >,</span
            >
          </template>
        </span>
      </template>
      <template #cell-totalOrder="{ rowData: { netAmount } }">
        {{ formatMoneyShekels(netAmount) }}
      </template>
      <template #cell-totalBill="{ rowData: { totalBill } }">
        <div>
          {{ formatTotalBill(totalBill) }}
        </div>
      </template>
      <template #cell-details="{ rowData: { imbalanceAmount, relatedBillings, relatedBillingAmount } }">
        <div v-if="imbalanceAmount">
          {{
            $t('modals.issues.billings.table.row.imbalanceAmount', {
              imbalanceAmount: formatMoney(imbalanceAmount),
            })
          }}
        </div>
        <div v-else-if="relatedBillings.length">
          {{ $t('eventMapModal.billingCard.alertBox.matchedAmount') }} {{ formatMoney(relatedBillingAmount) }}
        </div>
      </template>
    </Table>
    <DocumentModal
      v-if="displayedDocument.documentId"
      visible
      :document-id="displayedDocument.documentId"
      @close="displayedDocument = {}"
    >
      <template v-if="displayedDocument.documentsIds && displayedDocument.documentsIds.length > 1" #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="displayedDocument.documentsIds.length"
          :current-page="displayedDocument.index + 1"
          :pager-count="5"
          @update:currentPage="handleDocumentModalPagination"
        />
      </template>
    </DocumentModal>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { subtract, sum } from 'ramda';

import { options } from '@/locale/dateConfig';
import { currency } from '@/locale/numberConfig';
import { DocumentModal } from '@/modules/documentModal';
import { Table, Button } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const TABLE_HEADERS = {
  DATE: 'date',
  ORDER_TYPE: 'orderType',
  REFERENCE: 'reference',
  TOTAL_ORDER: 'totalOrder',
  TOTAL_BILL: 'totalBill',
  DETAILS: 'details',
};

export default {
  name: 'BillingTable',
  components: { Table, DocumentModal, Button },
  props: {
    events: { type: Array, default: () => [] },
    imbalances: { type: Array, default: () => [] },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();

    return {
      displayedDocument: ref({}),
      formatMoney: (value) => formatCentsToCurrency(value) ?? '',
      formatMoneyShekels: (value) => formatToCurrency(value) ?? '-',
      formatDate: (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, { ...options.short, timeZone: 'UTC' }),
    };
  },
  computed: {
    transformedEvents() {
      const transformedEvents = [];
      this.events.map((event) => {
        const relevantImbalances = this.imbalances.filter((imbalance) =>
          imbalance.orderedAmounts.some(({ id }) => id === event.id)
        );
        const imbalanceAmount = relevantImbalances.reduce(
          (amount, { billedAmounts, orderedAmounts }) =>
            amount +
            subtract(sum(billedAmounts.map(({ amount }) => amount)), sum(orderedAmounts.map(({ amount }) => amount))),
          0
        );

        const relatedBillingAmount = event.relatedBillings.reduce((total, { orderLinks }) => {
          const relevantOrderLink = orderLinks.find((link) => link.order && link.order.id === event.id);
          return total + relevantOrderLink.amount;
        }, 0);

        transformedEvents.push({
          ...event,
          refDocumentIds: event.eventReferences.map(({ documentId }) => documentId),
          imbalanceAmount: imbalanceAmount,
          relatedBillingAmount: Math.abs(relatedBillingAmount),
        });
      });
      return transformedEvents;
    },
    columns() {
      return [
        {
          header: this.$t('modals.issues.billings.table.header.onlyDate'),
          key: TABLE_HEADERS.DATE,
        },
        {
          header: this.$t('modals.issues.billings.table.header.orderType'),
          key: TABLE_HEADERS.ORDER_TYPE,
        },
        {
          header: this.$t('modals.issues.billings.table.header.reference'),
          key: TABLE_HEADERS.REFERENCE,
        },
        {
          header: this.$t('modals.issues.billings.table.header.totalOrder'),
          key: TABLE_HEADERS.TOTAL_ORDER,
        },
        {
          header: this.$t('modals.issues.billings.table.header.totalBill'),
          key: TABLE_HEADERS.TOTAL_BILL,
        },
        {
          header: this.$t('modals.issues.billings.table.header.details'),
          key: TABLE_HEADERS.DETAILS,
        },
      ];
    },
  },
  methods: {
    handleOpenDocument(documentId, documentsIds) {
      this.displayedDocument = {
        documentId,
        documentsIds,
        index: documentsIds.indexOf(documentId),
      };
    },
    handleDocumentModalPagination(page) {
      if (page - 1 === this.displayedDocument.index) return;
      const nextIndex = page - 1;
      this.displayedDocument = {
        ...this.displayedDocument,
        documentId: this.displayedDocument.documentsIds[nextIndex],
        index: nextIndex,
      };
    },
    formatTotalBill(value) {
      if (typeof value === 'number' && value !== 0) {
        return value.toLocaleString(this.$i18n.locale, currency);
      } else {
        return this.$t('commons.unknown');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '../../../commons/style';

.error {
  color: $error;
}

.table-goods-cells {
  display: flex;
  flex-direction: row;
  align-items: center;
  .table-goods-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    .table-goods-cell__dots {
      &__dot {
        margin-right: 3px;
        width: 6px;
        height: 6px;
        border-radius: 36px;
        transform: translateY(-1px);
      }

      &__dot--received {
        margin-left: 8px;
        background: $main-action;
      }
      &__dot--returned {
        margin-right: 8px;
        margin-left: 8px;
        background: $counter-action;
      }
      &__dot--returned--only {
        margin-left: 8px;
        background: $counter-action;
      }
    }
  }
}

.table-text-dot {
  &:first-child {
    &::after {
      position: absolute;
      content: '/';
    }

    $margin: 20px;

    [dir='rtl'] & {
      margin-left: $margin;
    }
    [dir='ltr'] & {
      margin-right: $margin;
    }
  }

  &::before {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 36px;
    position: absolute;
    transform: translate(10px, 5.5px);

    [dir='ltr'] & {
      transform: translate(-10px, 5px);
    }
  }

  &.table-colored-dot--received::before {
    background: $main-action;
  }
  &.table-colored-dot--returned::before {
    background: $counter-action;
  }
}
</style>
